/*
	Copyright 2016, Jered Danielson
	jered@uw.edu

	This file is part of APOD 2.0.

	APOD 2.0 is free software: you can redistribute it and/or modify
	it under the terms of the GNU General Public License as published by
	the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	APOD 2.0 is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU General Public License for more details.

	You should have received a copy of the GNU General Public License
	along with APOD 2.0.  If not, see <http://www.gnu.org/licenses/>.
*/

/* Begin Icomoon fonts */

@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?cz4m1i");
  src: url("fonts/icomoon.eot?cz4m1i#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?cz4m1i") format("truetype"),
    url("fonts/icomoon.woff?cz4m1i") format("woff"),
    url("fonts/icomoon.svg?cz4m1i#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info:before {
  content: "\ea0c";
}
.icon-last:before {
  content: "\ea22";
}
.icon-new-tab:before {
  content: "\ea7e";
}
.icon-mail4:before {
  content: "\ea86";
}
.icon-github:before {
  content: "\eab0";
}
.icon-tumblr:before {
  content: "\eab9";
}
.icon-stackoverflow:before {
  content: "\ead0";
}

/* End Icomoon fonts */

/* Begin document styles */

body {
  font-family: "Open sans", sans-serif;
}

#app {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

#sidebar-explanation {
  background: #202428;
  border: 1px solid #404448;
  transition: all 0.15s cubic-bezier(0.25, 0, 0, 1);
  transform: translateY(-8px);
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

#sidebar-title:hover {
  width: 720px;
  max-width: 50vw;
}

#sidebar-title:hover + #sidebar-explanation,
#sidebar-explanation:hover {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0px);
}

#sidebar-title {
  transition: all 0.15s cubic-bezier(0.25, 0, 0, 1);
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  font-size: 28px;
  max-width: 50vw;
  text-align: center;
}

@media screen and (max-width: 640px) {
  #sidebar-title {
    font-size: 16px;
    max-width: 40vw;
  }
  #sidebar-title:hover {
    width: auto !important;
    max-width: auto !important;
  }
  #info-container:hover {
    width: auto !important;
    max-width: auto !important;
  }
  #datebox {
    transform: scale(0.5);
    transform-origin: 0% 50%;
  }
}

#sidebar-title:hover {
}

.datebox-component {
  transition: 0.15s cubic-bezier(0.25, 0, 0, 1);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: transparent;
  border: 1px solid transparent;
  position: relative;
  min-width: 40px;
}

.datebox-component:hover,
.datebox-component.picker-is-open {
  background: #202428;
  border: 1px solid #404448;
}

.icon {
  margin: 20px;
  transition: 0.15s cubic-bezier(0.25, 0, 0, 1);
}

.icon:hover {
  color: orange;
}

.thumbnail:hover .thumbnail-overlay {
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border: 2px solid rgba(237, 243, 255, 0.5);
}

.date-hover {
  transition: 0.15s ease-out;
  opacity: 0;
  -webkit-transform: translate(-50%, 4px);
  transform: translate(-50%, 4px);
}

.thumbnail:hover .date-hover {
  opacity: 1;
  -webkit-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

img,
.thumbnail {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.day-picker,
.month-picker,
.year-picker {
  position: relative;
}

.day-picker-inner,
.month-picker-inner,
.year-picker-inner {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 5px);
  font-size: 2vh;
  line-height: 2.25vh;
  background: #202428;
  border: 1px solid #404448;
  border-top: 1px dashed #303438;
  color: white;
  min-width: 100%;
  text-align: center;
  z-index: 50;
}

.month-picker-inner .picker-element,
.year-picker-inner .picker-element {
  font-size: 16px;
  line-height: 16px;
  padding: 5px;
}

.picker-element {
  box-sizing: border-box;
  border: 1px solid transparent;
}

.picker-element:hover {
  background: #888;
  border: 1px solid #ccc;
}

.selected-picker-element {
  background: #555;
}

.arrow-nav {
  background: rgba(0, 4, 8, 0.25);
  width: 40px;
  height: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.35;
  z-index: 10;
  transition: all 0.15s cubic-bezier(0.25, 0, 0, 1);
}

.arrow-nav:hover {
  opacity: 1;
}

.arrow-nav.prev {
  left: 0;
}

.arrow-nav.next {
  right: 0;
  transform: scaleX(-1) translateY(-50%);
}

a {
  color: #6ec8cd;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.sidebar-sourceinfo {
  color: #888;
  margin-top: 20px;
}

#info-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  font-size: 28px;
  line-height: 80px;
  text-align: right;
  color: #ccc;
}

#info-container:hover {
  width: 480px;
  max-width: 100vw;
}

#info-container:hover #info-inner,
#info-inner:hover {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0px);
}

#info-inner {
  position: absolute;
  right: 0;
  top: 60px;
  width: 480px;
  max-width: 100vw;
  opacity: 0;
  padding: 0 20px;
  pointer-events: none;
  font-size: 16px;
  line-height: normal;
  background: #202428;
  border: 1px solid #404448;
  text-align: left;
  box-sizing: border-box;
  transform: translateY(-8px);
  transition: all 0.15s cubic-bezier(0.25, 0, 0, 1);
}

.loading-anim {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.LA-1,
.LA-2 {
  width: 80px;
  height: 80px;
  position: absolute;
  transform: translate(0%, 0%);
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  background: #202428;
  left: 50%;
  top: 50%;
}

.LA-1 {
  animation-name: LA-keys-1;
}

.LA-2 {
  animation-name: LA-keys-2;
}

@keyframes LA-keys-1 {
  0% {
    transform: translate(0%, 0%) rotate(0deg);
  }
  25% {
    transform: translate(-100%, 0%) rotate(0deg);
  }
  50% {
    transform: translate(-100%, -100%) rotate(-90deg);
  }
  75% {
    transform: translate(0%, -100%) rotate(-90deg);
  }
  100% {
    transform: translate(0%, 0%) rotate(-180deg);
  }
}

@keyframes LA-keys-2 {
  0% {
    transform: translate(-100%, -100%) rotate(0deg);
  }
  25% {
    transform: translate(0%, -100%) rotate(-90deg);
  }
  50% {
    transform: translate(0%, 0%) rotate(-90deg);
  }
  75% {
    transform: translate(-100%, 0%) rotate(-180deg);
  }
  100% {
    transform: translate(-100%, -100%) rotate(-180deg);
  }
}

/* End document styles */
